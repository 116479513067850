import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  // eslint-disable-next-line global-require
  require('promise/lib/rejection-tracking').enable();
  // eslint-disable-next-line global-require
  window.Promise = require('promise/lib/es6-extensions.js');
}

// eslint-disable-next-line global-require
require('whatwg-fetch');

/* eslint-disable */

// Element.matches polyfill
// https://developer.mozilla.org/ru/docs/Web/API/Element/matches
(function(e) {
  var matches = e.matches || e.matchesSelector || e.webkitMatchesSelector || e.mozMatchesSelector || e.msMatchesSelector || e.oMatchesSelector;
  !matches ? (e.matches = e.matchesSelector = function matches(selector) {
    var matches = document.querySelectorAll(selector);
    var th = this;
    return Array.prototype.some.call(matches, function(e) {
      return e === th;
    });
  }) : (e.matches = e.matchesSelector = matches);
})(Element.prototype);

// Element.closest polyfill
// https://developer.mozilla.org/ru/docs/Web/API/Element/closest
(function(e){
  e.closest = e.closest || function(css){
    var node = this;

    while (node) {
      if (node.matches(css)) return node;
      else node = node.parentElement;
    }
    return null;
  }
})(Element.prototype);

/* eslint-enable */
